import { dependencies } from 'dummyConfig';
import yup from 'yup';
import { IFormInputs } from './Fields';

export type documentNames = 'aadhaar' | 'pan' | 'form60' | 'gstin' | 'drivingLicense' | 'voterId' | 'passport'|"cin";
export type dropdown = 'dropdown'
export const insurerName = {
  bajaj: 'Bajaj Allianz General Insurance Company Limited',
  liberty: 'Liberty General Insurance Limited',
  magma: 'Magma HDI General Insurance Limited',
  shriram: 'Shriram General Insurance Company Limited',
  royal_sundaram: 'Royal Sundaram General Insurance Co. Limited',
  tata: 'Tata AIG General Insurance Company Limited',
  oriental: 'The Oriental Insurance Company Ltd',
  united: 'United India Insurance Company Limited',
  icici:'ICICI Lombard General Insurance Company Limited',
  hdfc:'HDFC ERGO General Insurance',
  chola:'Chola MS General Insurance Company India Limited',
  kotak:'Kotak Mahindra General Insurance',
  reliance:'Reliance General Insurance Company Limited',
  national:'National Insurance Company Limited'
} as const;

export type inputTypes = 'text' | 'dropdown' | 'upload' | 'date';

type ckycProvider = 'default' | 'alt';
export type Validation = yup.StringSchema<string | undefined, Record<string, any>, string | undefined>
export type FieldNameKey =  keyof IFormInputs| documentNames | dropdown | string ;
export type fieldSchema = {
  name: string;
  validation?: Validation;
  fileSizeLimit?: number;
  documentName?: documentNames[];
  ckycProvider?: ckycProvider;
  dependent?: dependentSchema[];
  supporting?: boolean;
  primary?: boolean;
  frontAndBack?: boolean;
  noInput?:boolean,
};
export type dependentSchema = {
  name: string;
  type: inputTypes;
  validation: Validation;
  fileSizeLimit?: number;
  key: FieldNameKey;
  dropdownValues?:any[],
  capitalize?:boolean,
  subType?:'date',
  // ckycProvider?: ckycProvider;
  frontAndBack?: boolean; // todo - REMOVE FRONT AND BACK KEY FROM EVERYWHERE , NOT NEEDED in DEPENDENT SCHEMA
  // documentNumber?:boolean,
};
export type insurerConfig = {
  name: (typeof insurerName)[keyof typeof insurerName];
  newVehicle: {
    Y: {
      od1tp5?: {
        min: number;
        max: number;
      };
      od1tp1?: {
        min: number;
        max: number;
      }
    };
    N: {
      od1tp1?: {
        notExpired: {
          min: number;
          max: number;
        };
        expired: {
          min: number;
          max: number;
        };
        maxVehicleAge: number;
      };
      od1?: {
        notExpired: {
          min: number;
          max: number;
        };
        expired: {
          min: number;
          max: number;
        };
        maxVehicleAge: number;
      };
      tp1?: {
        notExpired: {
          min: number;
          max: number;
        };
        expired: {
          min: number;
          max: number;
        };
        maxVehicleAge: number;
      };
    };
  };
  previousInsurers: string[];
  ckyc: {
    //? Default KYC Provider which will be used unless explicitly specified otherwise
    ckycProvider: ckycProvider;
    //? Dependents are fields that are required in addition to the primary fields
    dependent?: dependentSchema[];
    search?: {
      ckycProvider?: ckycProvider;
      dependent?: dependentSchema[];
      individual?: {
        ckycProvider?: ckycProvider;
        dependent?: dependentSchema[];
      } & {
        [key in documentNames]?: fieldSchema;
      };
      corporate?: {
        ckycProvider?: ckycProvider;
        dependent?: dependentSchema[];
      } & {
        [key in documentNames]?: fieldSchema;
      };
    };
    upload?: {
      ckycProvider?: ckycProvider;
      dependent?: dependentSchema[];
      individual?: {
        ckycProvider?: ckycProvider;
        dependent?: dependentSchema[];
      } & {
        [key in documentNames]?: fieldSchema;
      };
      corporate?: {
        ckycProvider?: ckycProvider;
        dependent?: dependentSchema[];
      } & {
        [key in documentNames]?: fieldSchema;
      };
    };
  };
  inspectionRequired?: boolean;
  inspectionSizeLimit?: number;
  inspectionTypes?: {
    front: 'Front';
    left: 'Left';
    right: 'Right';
    rear: 'Rear';
    odometer: 'Odometer';
    chassisNumber: 'Chassis Number';
    report: 'Inspection Report';
    agent: "Agent with vehicle holding today's newspaper";
  };
};
// ? Actual united config
export const unitedConfig: insurerConfig = {
  name: 'United India Insurance Company Limited',
    newVehicle: {
      Y: {
        od1tp5: {
          min: 0,
          max: 30,
        },
        od1tp1: {
          min: 0,
          max: 30,
        }
      },
      N: {
        od1tp1: {
          notExpired: {
            min: 1,
            max: 30,
          },
          expired: {
            min: 2,
            max: 2,
          },
          maxVehicleAge: 15,
        },
        od1: {
          notExpired: {
            min: 1,
            max: 30,
          },
          expired: {
            min: 2,
            max: 2,
          },
          maxVehicleAge: 15,
        },
        tp1: {
          notExpired: {
            min: 1,
            max: 30,
          },
          expired: {
            min: 2,
            max: 2,
          },
          maxVehicleAge: 15,
        }
      },
    },
    previousInsurers: [
      'ACKO GENERAL INSURANCE LIMITED',
      'BAJAJ ALLIANZ GENERAL INSURANCE CO.LTD',
      'BHARTI AXA GENERAL INSURANCE COMPANY LIMITED',
      'CHOLAMANDALAM MS GENERAL INSURANCE CO.LTD.',
      'DHFL GENERAL INSURANCE LIMITED',
      'EDELWEISS GENERAL INSURANCE COMPANY',
      'FUTURE GENERALI INDIA INSURANCE COMPANY LIMITED',
      'GO DIGIT GENERAL INSURANCE LIMITED',
      'HDFC ERGO GENERAL INSURANCE CO.LTD.',
      'ICICI LOMBARD GENERAL INSURANCE CO. LTD.',
      'IFFCO TOKIO GENERAL INSURANCE CO. LTD.',
      'Kotak General Insurance',
      'L&T GENERAL INSURANCE COMPANY LIMITED',
      'LIBERTY VIDEOCON GENERAL INSURANCE COMPANY',
      'MAGMA HDI GENERAL INSURANCE CO.LTD',
      'NATIONAL INSURANCE CO.LTD.',
      'NATIONAL INSURANCE COMPANY LIMITED',
      'RAHEJA QBE GENERAL INSURANCE COMPANY LIMITED',
      'RELIANCE GENERAL INSURANCE CO.LTD.',
      'ROYAL SUNDARAM ALLIANCE INSURANCE CO.LTD.',
      'SAP Insurance Company',
      'SBI GENERAL INSURANCE COMPANY LIMITED',
      'SHRIRAM GENERAL INSURANCE COMPANY LIMITED',
      'TATA AIG GENERAL INSURANCE CO.LTD.',
      'THE NEW INDIA ASSURANCE CO. LTD.',
      'THE ORIENTAL INSURANCE CO. LTD.',
      'UNITED INDIA INSURANCE CO.LTD.',
      'UNIVERSAL SOMPO GENERAL INSURANCE CO.LTD.'
    ],
    ckyc: {
      ckycProvider: 'default',
      search: {
        individual: {
          aadhaar: {
            name: 'Aadhaar Card',
            primary: true
          }
        },
        corporate: {
          dependent: [
            dependencies.kycGstin
          ],
          pan: {
            name: 'PAN Card',
            primary: true
          }
        }
      },
      upload: {
	// ckycProvider:"alt",
        individual: {
          aadhaar: {
            name: 'Aadhaar Card',
            primary: true
          },
          pan: {
            name: 'PAN Card',
            primary: true,
            // dependent: [dependencies.aadhaarEnrollmentNumber, dependencies.aadhaarEnrollmentDate]
          },
          // form60: {
          //   name: 'Form 60',
          //   supporting: true
          // }
        },
        corporate: {
          dependent: [
            dependencies.kycGstin
          ],
          pan: {
            name: 'PAN Card',
            primary: true
          }
        },
      },
    },
    inspectionRequired: true,
    inspectionSizeLimit: 0.48,
    inspectionTypes: {
      front: 'Front',
      left: 'Left',
      right: 'Right',
      rear: 'Rear',
      odometer: 'Odometer',
      chassisNumber: 'Chassis Number',
      report: 'Inspection Report',
      agent: "Agent with vehicle holding today's newspaper",
    }
};

export const bajajConfig: insurerConfig = {
  name: 'Bajaj Allianz General Insurance Company Limited',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 0,
      },
      // od1tp1: {
      //   min: 0,
      //   max: 0,
      // },
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
    },
  },
  previousInsurers: [
    'Acko General Insurance Limited',
    'Bajaj Allianz General Insurance Co Ltd.',
    'Bharti AXA General Insurance Company Limited',
    'Cholamandalam MS General Insurance Company Limited.',
    'DHFL General Insurance Limited',
    'Edelweiss General Insurance Company Limited',
    'Future Generali India Insurance Company Limited.',
    'Go Digit General Insurance Limited',
    'HDFC ERGO General Insurance Company Limited.',
    'HDFC General Insurance Company Ltd.',
    'ICICI Lombard General Insurance Company Limited.',
    'IFFCO Tokio General Insurance Company Limited.',
    'KOTAK MAHINDRA GENERAL INSURANCE',
    'L&T General Insurance Company Limited',
    'Liberty Videocon General Insurance Company Limited',
    'Magma HDI General Insurance Company Limited',
    'National Insurance Company Limited',
    'Raheja QBE General Insurance Company Limited',
    'Reliance General Insurance Company Limited.',
    'Royal Sundaram Alliance Insurance Company Limited.',
    'SBI General Insurance Company Limited',
    'Shriram General Insurance Company Limited.',
    'Tata AIG General Insurance Company Limited',
    'The New India Assurance Company Limited',
    'The Oriental Insurance Company Limited',
    'United India Insurance Company Limited',
    'Universal Sompo General Insurance Company Limited ',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          primary: true
        },
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        }
      },
    },
    upload: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true,
          frontAndBack: true
        },
        drivingLicense: {
          name: 'Driving License',
          primary: true
        },
        voterId: {
          name: 'Voter ID',
          primary: true
        },
        passport: {
          name: 'Passport',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          supporting: true
        },
        form60: {
          name: 'Form 60',
          supporting: true
        }
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        },
        gstin: {
          name: 'GSTIN',
          supporting: true
        },
      },
    },
  },
};

export const shriramConfig: insurerConfig = {
  name: 'Shriram General Insurance Company Limited',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    }
  },
  previousInsurers: [
    'Acko General Insurance Ltd',
    'Bajaj Allianz General Insurance Company Limited',
    'BHARATI AXA GENERAL INSURANCE CO LTD',
    'Cholamandalam MS General Insurance Co Ltd',
    'DHFL General Insurance Ltd',
    'Edelweiss General Insurance Co Ltd',
    'FUTURE GENERALI INDIA INSURANCE COMPANY LTD.',
    'Go Digit General Insurance Ltd',
    'HDFC ERGO GENERAL INSURANCE COMPANY LTD.',
    'ICICI Lombard General Insurance Company Limited',
    'IFFCO TOKIO General Insurance Company Limited',
    'KOTAK MAHINDRA GENERAL INSURANCE COMPANY LTD.',
    'Liberty General Insurance Ltd',
    'Magma HDI General Insurance Co Ltd',
    'National Insurance Company Limited',
    'Navi General Insurance Ltd',
    'Oriental Insurance Company Limited',
    'Raheja QBE General Insurance Co Ltd',
    'Reliance General Insurance Co Ltd',
    'Royal Sundaram General Insurance Co Ltd',
    'SBI General Insurance Co Ltd',
    'SHRIRAM GENERAL INSURANCE COMPANY LTD',
    'Tata AIG General Insurance Co Ltd',
    'The New India Assurance Co Ltd',
    'United India Insurance Company Limited',
    'Universal Sompo General Insurance Co Ltd',
  ],
  ckyc: {
    ckycProvider: 'default',
    // search: {
    //   individual: {
    //     aadhaar: {
    //       name: 'Aadhaar Card',
    //       primary: true
    //     },
    //     pan: {
    //       name: 'PAN Card',
    //       primary: true
    //     },
    //   },
    //   corporate: {
    //     pan: {
    //       name: 'PAN Card',
    //       primary: true
    //     }
    //   },
    // },
    upload: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        drivingLicense: {
          name: 'Driving License',
          primary: true
        },
        voterId: {
          name: 'Voter ID',
          primary: true
        },
        passport: {
          name: 'Passport',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          supporting: true
        },
        form60: {
          name: 'Form 60',
          supporting: true
        }
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        },
        gstin: {
          name: 'GSTIN',
          supporting: true
        },
      },
    },
  }
};

export const magmaConfig: insurerConfig = {
  name: 'Magma HDI General Insurance Limited',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    }
  },
  previousInsurers: [
    'ACKO',
    'ADBHI',
    'AICI',
    'BAJAJ',
    'BHARATI',
    'CHIL',
    'CIGNA',
    'CMGI',
    'EDELWEISS',
    'FGIIC',
    'GDGIL',
    'GIC',
    'GIF',
    'GO DIGIT',
    'HDFCERGO',
    'ICLB',
    'IFFCO',
    'KOTAK',
    'LGICL',
    'MAGMA',
    'NAVI GI LTD.',
    'NBHICL',
    'NICL',
    'OIC',
    'RGIC',
    'RQGIC',
    'RSGICL',
    'SBI',
    'SGIC',
    'SHAIC',
    'TAGI',
    'TNIA',
    'UIIC',
    'USGI',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          primary: true
        },
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        }
      },
    },
    upload: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          supporting: true
        },
        form60: {
          name: 'Form 60',
          supporting: true
        }
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        },
        gstin: {
          name:'GSTIN',
          supporting: true
        },
        cin: {
          name: 'CIN',
          supporting: true
        }
      },
    },
  },
};

export const royalSundaramConfig: insurerConfig = {
  name: 'Royal Sundaram General Insurance Co. Limited',
    newVehicle: {
      Y: {
        od1tp5: {
          min: 0,
          max: 0,
        }
      },
      N: {
        // od1tp1: {
        //   notExpired: {
        //     min: 1,
        //     max: 30,
        //   },
        //   expired: {
        //     min: 3,
        //     max: 3,
        //   },
        //   maxVehicleAge: 15,
        // },
        // od1: {
        //   notExpired: {
        //     min: 1,
        //     max: 30,
        //   },
        //   expired: {
        //     min: 3,
        //     max: 3,
        //   },
        //   maxVehicleAge: 15,
        // },
        // tp1: {
        //   notExpired: {
        //     min: 1,
        //     max: 30,
        //   },
        //   expired: {
        //     min: 3,
        //     max: 3,
        //   },
        //   maxVehicleAge: 15,
        // }
      },
    },
    previousInsurers: [
      'Acko General Insurance Limited',
      'Bajaj Allianz General Insurance Co Ltd.',
      'Bharti AXA General Insurance Company Limited',
      'Cholamandalam MS General Insurance Company Limited.',
      'DHFL General Insurance Limited',
      'Edelweiss General Insurance Company Limited',
      'Future Generali India Insurance Company Limited.',
      'Go Digit General Insurance Limited',
      'HDFC ERGO General Insurance Company Limited.',
      'HDFC General Insurance Company Ltd.',
      'ICICI Lombard General Insurance Company Limited.',
      'IFFCO Tokio General Insurance Company Limited.',
      'KOTAK MAHINDRA GENERAL INSURANCE',
      'L&T General Insurance Company Limited',
      'Liberty Videocon General Insurance Company Limited',
      'Magma HDI General Insurance Company Limited',
      'National Insurance Company Limited',
      'Raheja QBE General Insurance Company Limited',
      'Reliance General Insurance Company Limited.',
      'Royal Sundaram Alliance Insurance Company Limited.',
      'SBI General Insurance Company Limited',
      'Shriram General Insurance Company Limited.',
      'Tata AIG General Insurance Company Limited',
      'The New India Assurance Company Limited',
      'The Oriental Insurance Company Limited',
      'United India Insurance Company Limited',
      'Universal Sompo General Insurance Company Limited ',
    ],
    ckyc: {
      ckycProvider: 'default',
      // search: {
      //   individual: {
      //     aadhaar: {
      //       name: 'Aadhaar Card',
      //       primary: true
      //     },
      //     pan: {
      //       name: 'PAN Card',
      //       primary: true
      //     },
      //   },
      //   corporate: {
      //     pan: {
      //       name: 'PAN Card',
      //       primary: true
      //     }
      //   },
      // },
      upload: {
        individual: {
          aadhaar: {
            name: 'Aadhaar Card',
            primary: true,
            frontAndBack: true,
          },
          pan: {
            name: 'PAN Card',
            supporting: true
          },
          form60: {
            name: 'Form 60',
            supporting: true
          }
        },
        corporate: {
          pan: {
            name: 'PAN Card',
            primary: true
          },
          gstin: {
            name: 'GSTIN',
            supporting: true
          },
        },
      },
    },
}

export const orientalConfig: insurerConfig = {
  name: 'The Oriental Insurance Company Ltd',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 0,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General Insurance Limited',
    'Bajaj Allianz General Insurance Co Ltd.',
    'Bharti AXA General Insurance Company Limited',
    'Cholamandalam MS General Insurance Company Limited.',
    'DHFL General Insurance Limited',
    'Edelweiss General Insurance Company Limited',
    'Future Generali India Insurance Company Limited.',
    'Go Digit General Insurance Limited',
    'HDFC ERGO General Insurance Company Limited.',
    'HDFC General Insurance Company Ltd.',
    'ICICI Lombard General Insurance Company Limited.',
    'IFFCO Tokio General Insurance Company Limited.',
    'KOTAK MAHINDRA GENERAL INSURANCE',
    'L&T General Insurance Company Limited',
    'Liberty Videocon General Insurance Company Limited',
    'Magma HDI General Insurance Company Limited',
    'National Insurance Company Limited',
    'Raheja QBE General Insurance Company Limited',
    'Reliance General Insurance Company Limited.',
    'Royal Sundaram Alliance Insurance Company Limited.',
    'SBI General Insurance Company Limited',
    'Shriram General Insurance Company Limited.',
    'Tata AIG General Insurance Company Limited',
    'The New India Assurance Company Limited',
    'The Oriental Insurance Company Limited',
    'United India Insurance Company Limited',
    'Universal Sompo General Insurance Company Limited ',
  ],
  ckyc: {
    ckycProvider: 'default',
    // search: {
    //   individual: {
    //     aadhaar: {
    //       name: 'Aadhaar Card',
    //       primary: true
    //     },
    //     pan: {
    //       name: 'PAN Card',
    //       primary: true
    //     },
    //   },
    //   corporate: {
    //     pan: {
    //       name: 'PAN Card',
    //       primary: true
    //     }
    //   },
    // },
    upload: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true,
          frontAndBack: true,
        },
        pan: {
          name: 'PAN Card',
          supporting: true
        }
      },
      corporate: {
        dependent: [
          dependencies.kycGstin
        ],
        pan: {
          name: 'PAN Card',
          primary: true
        }
      },
    },
  },
};

export const tataConfig: insurerConfig = {
  name: 'Tata AIG General Insurance Company Limited',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 0,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General Insurance Limited',
    'Bajaj Allianz General Insurance Co Ltd.',
    'Bharti AXA General Insurance Company Limited',
    'Cholamandalam MS General Insurance Company Limited.',
    'DHFL General Insurance Limited',
    'Edelweiss General Insurance Company Limited',
    'Future Generali India Insurance Company Limited.',
    'Go Digit General Insurance Limited',
    'HDFC ERGO General Insurance Company Limited.',
    'HDFC General Insurance Company Ltd.',
    'ICICI Lombard General Insurance Company Limited.',
    'IFFCO Tokio General Insurance Company Limited.',
    'KOTAK MAHINDRA GENERAL INSURANCE',
    'L&T General Insurance Company Limited',
    'Liberty Videocon General Insurance Company Limited',
    'Magma HDI General Insurance Company Limited',
    'National Insurance Company Limited',
    'Raheja QBE General Insurance Company Limited',
    'Reliance General Insurance Company Limited.',
    'Royal Sundaram Alliance Insurance Company Limited.',
    'SBI General Insurance Company Limited',
    'Shriram General Insurance Company Limited.',
    'Tata AIG General Insurance Company Limited',
    'The New India Assurance Company Limited',
    'The Oriental Insurance Company Limited',
    'United India Insurance Company Limited',
    'Universal Sompo General Insurance Company Limited ',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          primary: true
        },
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        }
      },
    },
    upload: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true,
          frontAndBack: true
        },
        drivingLicense: {
          name: 'Driving License',
          primary: true
        },
        voterId: {
          name: 'Voter ID',
          primary: true
        },
        passport: {
          name: 'Passport',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          supporting: true
        },
        form60: {
          name: 'Form 60',
          supporting: true
        }
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        },
        cin: {
          name: 'CIN',
          supporting: true
        },
      },
    },
  }
};

export const iciciConfig: insurerConfig = {
  name: 'ICICI Lombard General Insurance Company Limited',
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 0,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General Insurance Limited',
    'Bajaj Allianz General Insurance Co Ltd.',
    'Bharti AXA General Insurance Company Limited',
    'Cholamandalam MS General Insurance Company Limited.',
    'DHFL General Insurance Limited',
    'Edelweiss General Insurance Company Limited',
    'Future Generali India Insurance Company Limited.',
    'Go Digit General Insurance Limited',
    'HDFC ERGO General Insurance Company Limited.',
    'HDFC General Insurance Company Ltd.',
    'ICICI Lombard General Insurance Company Limited.',
    'IFFCO Tokio General Insurance Company Limited.',
    'KOTAK MAHINDRA GENERAL INSURANCE',
    'L&T General Insurance Company Limited',
    'Liberty Videocon General Insurance Company Limited',
    'Magma HDI General Insurance Company Limited',
    'National Insurance Company Limited',
    'Raheja QBE General Insurance Company Limited',
    'Reliance General Insurance Company Limited.',
    'Royal Sundaram Alliance Insurance Company Limited.',
    'SBI General Insurance Company Limited',
    'Shriram General Insurance Company Limited.',
    'Tata AIG General Insurance Company Limited',
    'The New India Assurance Company Limited',
    'The Oriental Insurance Company Limited',
    'United India Insurance Company Limited',
    'Universal Sompo General Insurance Company Limited ',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        pan: {
          name: 'PAN Card',
          primary: true
        },
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        }
      },
    },
    upload: {
      individual: {
        aadhaar: {
          name: 'Aadhaar Card',
          primary: true
        },
        drivingLicense: {
          name: 'Driving License',
          primary: true
        },
        voterId: {
          name: 'Voter ID',
          primary: true
        },
        passport: {
          name: 'Passport',
          primary: true
        }
      },
      corporate: {
        pan: {
          name: 'PAN Card',
          primary: true
        },
        gstin: {
          name: 'GSTIN',
          supporting: true
        },
        cin: {
          name: 'CIN',
          supporting: true
        },
      },
    },
  }
};
export const libertyConfig: insurerConfig = {
  name: 'Liberty General Insurance Limited',
  newVehicle: {
    Y: {
      // od1tp5: {
      //   min: 0,
      //   max: 30,
      // }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      od1: {
        notExpired: {
          min: 1,
          max: 30,
        },
        expired: {
          min: 2,
          max: 2,
        },
        maxVehicleAge: 15,
      },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General',
    'BAJAJ ALLIANZ',
    'BHARTI AXA',
    'Cholamandalam',
    'Edelweiss General',
    'FUTURE GENERALI',
    'Go Digit General',
    'HDFC ERGO',
    'ICICI Lombard',
    'IFFCO TOKIO',
    'Kotak Mahindra General Insurance',
    'MAGMA HDI',
    'National Insurance',
    'NAVI GENERAL INSURANCE LIMITED',
    'New India Ins',
    'ORIENTAL INS',
    'Raheja QBE General',
    'Reliance general',
    'Royal Sundaram',
    'SBI General',
    'SHRIRAM GENERAL',
    'TATA AIG',
    'UNITED INDIA',
    'Universal Sompo',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
      individual: {
        aadhaar: {
            name: 'Aadhaar Card',
            primary: true
        },
        pan: {
            name: 'PAN Card',
            primary: true
        },
      },
      corporate: {
        pan: {
            name: 'PAN Card',
            primary: true
        },
        // cin: {
        //     name: 'CIN',
        //     primary: true
        // },
      },
    },
    upload: {
        individual: {
            pan: {
                name: 'PAN Card',
                primary: true
            },
            form60: {
                name: 'Form 60',
                primary: true
            },
            aadhaar: {
                name: 'Aadhaar Card',
                supporting: true,
                frontAndBack: true
            },
            drivingLicense: {
                name: 'Driving license',
                supporting: true,
                frontAndBack: true
            },
            passport: {
                name: 'Passport',
                supporting: true,
                frontAndBack: true
            },
            voterId: {
                name: 'Voter ID',
                supporting: true,
                frontAndBack: true
            },

        },
        corporate: {
            pan: {
                name: 'PAN Card',
                primary: true
            },
            gstin: {
                name: 'GSTIN',
                supporting: true
            },
            cin: {
                name: 'CIN',
                supporting: true
            },
        },
    },
},
}
export const hdfcConfig: insurerConfig = {
  name: insurerName.hdfc,
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 3,
      //     max: 3,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General Insurance Limited',
    'Bajaj Allianz General Insurance Co Ltd.',
    'Bharti AXA General Insurance Company Limited',
    'Cholamandalam MS General Insurance Company Limited.',
    'DHFL General Insurance Limited',
    'Edelweiss General Insurance Company Limited',
    'Future Generali India Insurance Company Limited.',
    'Go Digit General Insurance Limited',
    'HDFC ERGO General Insurance Company Limited.',
    'HDFC General Insurance Company Ltd.',
    'ICICI Lombard General Insurance Company Limited.',
    'IFFCO Tokio General Insurance Company Limited.',
    'KOTAK MAHINDRA GENERAL INSURANCE',
    'L&T General Insurance Company Limited',
    'Liberty Videocon General Insurance Company Limited',
    'Magma HDI General Insurance Company Limited',
    'National Insurance Company Limited',
    'Raheja QBE General Insurance Company Limited',
    'Reliance General Insurance Company Limited.',
    'Royal Sundaram Alliance Insurance Company Limited.',
    'SBI General Insurance Company Limited',
    'Shriram General Insurance Company Limited.',
    'Tata AIG General Insurance Company Limited',
    'The New India Assurance Company Limited',
    'The Oriental Insurance Company Limited',
    'United India Insurance Company Limited',
    'Universal Sompo General Insurance Company Limited ',
  ],
  ckyc: {
    ckycProvider: 'default',
    // search: {
    //   individual: {
    //     pan: {
    //         name: 'PAN Card',
    //         primary: true
    //     },
    //   },
    //   corporate: {
    //       pan: {
    //           name: 'PAN Card',
    //           primary: true
    //       },
    //   },
    // },
  upload: {
    individual: {
      pan: {
        name: 'PAN Card',
        primary: true
      },
      aadhaar: {
        dependent:[dependencies.motherName, dependencies.fatherName],
        name: 'Aadhaar Card',
        primary: true,
        frontAndBack: true
      }
    },
    corporate: {
      pan: {
          name: 'PAN Card',
          primary: true
      },
      gstin: {
          name: 'GSTIN',
          supporting: true
      },
      cin: {
          name: 'CIN',
          supporting: true
      },
    },
  },
},
};

export const cholaConfig: insurerConfig = {
  name: insurerName.chola,
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General',
    'BAJAJ ALLIANZ',
    'BHARTI AXA',
    'Cholamandalam',
    'Edelweiss General',
    'FUTURE GENERALI',
    'Go Digit General',
    'HDFC ERGO',
    'ICICI Lombard',
    'IFFCO TOKIO',
    'Kotak Mahindra General Insurance',
    'MAGMA HDI',
    'National Insurance',
    'NAVI GENERAL INSURANCE LIMITED',
    'New India Ins',
    'ORIENTAL INS',
    'Raheja QBE General',
    'Reliance general',
    'Royal Sundaram',
    'SBI General',
    'SHRIRAM GENERAL',
    'TATA AIG',
    'UNITED INDIA',
    'Universal Sompo',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
        individual: {
            aadhaar: {
                name: 'Aadhaar Card',
                primary: true
            },
            pan: {
                name: 'PAN Card',
                primary: true
            },
        },
        corporate: {
            pan: {
                name: 'PAN Card',
                primary: true
            }
        },
    },
    // ? UNCOMMENT UPLOAD OBJECT TO ENABLE UPLOAD DOCUMENTS IN CHOLA
    upload: { 
      ckycProvider:'default',
        individual: {
            // pan: {
            //     name: 'PAN Card',
            //     supporting: true
            // },
            // form60: {
            //   name: 'Form 60',
            //   supporting: true
            // },
            aadhaar: {
                name: 'Aadhaar Card',
                frontAndBack: true,
                primary: true,
            },

            // drivingLicense: {
            //     name: 'Driving license',
            //     primary: true,
            // },
            // passport: {
            //     name: 'Passport',
            //     primary: true,
            // },
            // voterId: {
            //     name: 'Voter ID',
            //     primary: true,
            // },

        },
        // corporate: {
        //     pan: {
        //         name: 'PAN Card',
        //         primary: true
        //     }
        // },
    },
},
}

export const kotakConfig: insurerConfig = {
  name: insurerName.kotak,
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General',
    'BAJAJ ALLIANZ',
    'BHARTI AXA',
    'Cholamandalam',
    'Edelweiss General',
    'FUTURE GENERALI',
    'Go Digit General',
    'HDFC ERGO',
    'ICICI Lombard',
    'IFFCO TOKIO',
    'Kotak Mahindra General Insurance',
    'MAGMA HDI',
    'National Insurance',
    'NAVI GENERAL INSURANCE LIMITED',
    'New India Ins',
    'ORIENTAL INS',
    'Raheja QBE General',
    'Reliance general',
    'Royal Sundaram',
    'SBI General',
    'SHRIRAM GENERAL',
    'TATA AIG',
    'UNITED INDIA',
    'Universal Sompo',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
        individual: {
            pan: {
                name: 'PAN Card',
                primary: true
            },
        },
        corporate: {
            pan: {
                name: 'PAN Card',
                primary: true
            }
        },
    },
    // upload: {
    //     individual: {
    //         pan: {
    //             name: 'PAN Card',
    //             primary: true
    //         }
    //     },
    //     corporate: {
    //         pan: {
    //             name: 'PAN Card',
    //             primary: true
    //         }
    //     },
    // },
},
}
export const relianceConfig: insurerConfig = {
  name: insurerName.reliance,
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      od1tp1: {
        notExpired: {
          min: 1,
          max: 30,
        },
        expired: {
          min: 2,
          max: 2,
        },
        maxVehicleAge: 15,
      },
      od1: {
        notExpired: {
          min: 1,
          max: 30,
        },
        expired: {
          min: 2,
          max: 2,
        },
        maxVehicleAge: 15,
      },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },// tp not expired, od expiry date can be today
  previousInsurers: [
    "Cholamandalam MS General Insurance Company Ltd.",
    "Future Generali India Insurance Company Ltd",
    "HDFC ERGO General Insurance Company Ltd",
    "ICICI Lombard General Insurance Company Ltd",
    "IFFCO TOKIO General Insurance Company Ltd",
    "National Insurance Company Limited",
    "The Oriental Insurance Company Limited",
    "Royal Sundaram General Insurance Company Ltd.",
    "TATA AIG General Insurance Company Ltd.",
    "The New India Assurance company Ltd",
    "United India Insurance company Ltd",
    "Bajaj Allianz General Insurance Company Ltd.",
    "Bharti AXA General Insurance Company Ltd.",
    "Shriram General Insurance Company Ltd.",
    "Universal Sompo General Insurance Company Ltd.",
    "Raheja QBE General Insurance Company Ltd.",
    "L And T GENERAL INSURANCE CO LTD",
    "SBI General Insurance Company Ltd.",
    "Magma HDI General insurance Company Ltd",
    "Liberty General Insurance Ltd.",
    "KOTAK MAHINDRA GENERAL INSURANCE COMPANY LIMITED",
    "Navi General Insurance Ltd.",
    "Acko General Insurance Ltd",
    "Go Digit General Insurance Ltd.",
    "Edelweiss General Insurance Co Ltd",
    "Pahoja insurance Ltd",
    "GOVERNMENT OF KERALA KERALA STATE INSURANCE DEPARTMENT"
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
        individual: {
            aadhaar: {
                dependent: [dependencies.fatherName],
                name: 'Aadhaar Card',
                primary: true
            },
            pan: {
                name: 'PAN Card',
                primary: true
            },
        },
        corporate: {
            pan: {
                name: 'PAN Card',
                primary: true
            }
        },
    },
    // ? UNCOMMENT UPLOAD OBJECT TO ENABLE UPLOAD DOCUMENTS IN CHOLA
    upload: { 
      dependent: [dependencies.fatherName], // existing tp policy, previous od policy(if ncb > 0%)
        individual: {
              aadhaar: {
                name: 'Aadhaar Card',
                frontAndBack: true,
                primary: true,
              },
              drivingLicense: {
                name: 'Driving license',
                primary: true,
                frontAndBack: true,
              },
              passport: {
                name: 'Passport',
                primary: true,
                frontAndBack: true,
              },
              voterId: {
                name: 'Voter ID',
                primary: true,
                frontAndBack: true,
              },
            pan: {
                name: 'PAN Card',
                supporting: true
            },
            form60: {
              name: 'Form 60',
              supporting: true
            },
        },
        corporate: {
            pan: {
                name: 'PAN Card',
                primary: true
            },
            gstin: {
              name: 'GSTIN',
              supporting: true
            },
            cin: {
              name: 'CIN',
              supporting: true
            },
        },
    },
},
}
export const nationalConfig: insurerConfig = {
  name: insurerName.national,
  newVehicle: {
    Y: {
      od1tp5: {
        min: 0,
        max: 30,
      }
    },
    N: {
      // od1tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // od1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // },
      // tp1: {
      //   notExpired: {
      //     min: 1,
      //     max: 30,
      //   },
      //   expired: {
      //     min: 2,
      //     max: 2,
      //   },
      //   maxVehicleAge: 15,
      // }
    },
  },
  previousInsurers: [
    'Acko General',
    'BAJAJ ALLIANZ',
    'BHARTI AXA',
    'Cholamandalam',
    'Edelweiss General',
    'FUTURE GENERALI',
    'Go Digit General',
    'HDFC ERGO',
    'ICICI Lombard',
    'IFFCO TOKIO',
    'Kotak Mahindra General Insurance',
    'MAGMA HDI',
    'National Insurance',
    'NAVI GENERAL INSURANCE LIMITED',
    'New India Ins',
    'ORIENTAL INS',
    'Raheja QBE General',
    'Reliance general',
    'Royal Sundaram',
    'SBI General',
    'SHRIRAM GENERAL',
    'TATA AIG',
    'UNITED INDIA',
    'Universal Sompo',
  ],
  ckyc: {
    ckycProvider: 'default',
    search: {
        individual: {
            aadhaar: {
                name: 'Aadhaar Card',
                primary: true
            },
            pan: {
                name: 'PAN Card',
                primary: true
            },
        },
        corporate: {
            pan: {
                name: 'PAN Card',
                primary: true
            }
        },
    },
    // ? UNCOMMENT UPLOAD OBJECT TO ENABLE UPLOAD DOCUMENTS IN CHOLA
    upload: { 
      ckycProvider:'default',
        individual: {
            pan: {
                name: 'PAN Card',
                primary: true
            },
            // form60: {
            //   name: 'Form 60',
            //   supporting: true
            // },
            aadhaar: {
                name: 'Aadhaar Card',
                frontAndBack: true,
                primary: true,
            },

            // drivingLicense: {
            //     name: 'Driving license',
            //     primary: true,
            // },
            // passport: {
            //     name: 'Passport',
            //     primary: true,
            // },
            // voterId: {
            //     name: 'Voter ID',
            //     primary: true,
            // },

        },
        // corporate: {
        //     pan: {
        //         name: 'PAN Card',
        //         primary: true
        //     }
        // },
    },
},
}

